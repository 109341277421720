import React from 'react';
import { VerticalCenter } from '../../Components/SimpleLayouts';
import WaffleText from '../../Components/WaffleText';

export const HeaderBox = ({
  title,
  width,
}: {
  title: string;
  width: number;
}) => (
  <VerticalCenter
    style={{
      width,
      height: 61,
      backgroundColor: '#E5E5E5',
      shadowRadius: 10,
      shadowOffset: { width: 0, height: 4 },
      shadowColor: 'rgba(108,62,12,0.5)',
      shadowOpacity: 1,
      elevation: 5,
    }}>
    <WaffleText
      style={{
        color: '#373737',
        fontSize: 24,
        fontWeight: '700',
      }}>
      {title}
    </WaffleText>
  </VerticalCenter>
);
