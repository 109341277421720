import React, { useLayoutEffect } from 'react';
import { WebView } from 'react-native-webview';
import { Logger } from '../Helper/Logger';
import { Loading } from '../Components/Loading';
import { StackScreenProps } from '@react-navigation/stack';
import { RootStackParamList } from '../../screen-config';
import { Platform } from 'react-native';
import { PdfViewer } from '../Components/PdfViewer';

const WebScreen = ({
  navigation,
  route,
}: StackScreenProps<RootStackParamList, 'Web'>) => {
  const uri = route?.params.uri ?? 'https://www.trywaffle.com/';
  const loadingText = route?.params.loadingText;
  const headers = route?.params.headers ?? {};
  const method = route?.params.method ?? 'GET';
  const onMessage =
    route?.params.onMessage ??
    ((e) => {
      Logger(`Received message: ${JSON.stringify(e?.nativeEvent?.data)}`);
    });

  useLayoutEffect(() => {
    navigation.setOptions({
      title: route?.params.title ?? 'Web',
      headerBackTitleVisible: false,
      gestureEnabled: false,
    });
  }, [navigation]);

  if (Platform.OS === 'ios' && uri.endsWith('.pdf')) {
    return <PdfViewer source={{ uri }} style={{ flex: 1 }} />;
  } else {
    return (
      <WebView
        source={{ uri, headers, method }}
        startInLoadingState={true}
        onMessage={onMessage}
        renderLoading={() => <Loading loadingText={loadingText} />}
      />
    );
  }
};

export default WebScreen;
