import React, { useLayoutEffect } from 'react';
import styled from 'styled-components/native';
import { WaffleHighlightButton } from '../Components/WaffleHighlightButton';
import { StackScreenProps } from '@react-navigation/stack';
import {
  LoginScreenName,
  RootStackParamList,
  StartQuoteScreenName,
} from '../../screen-config';
import { Spacer } from '../Components/SimpleComponents';
import { Logger } from '../Helper/Logger';

const WelcomeView = styled.View`
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LogoImage = styled.Image`
  width: 219px;
  height: 54px;
  margin-bottom: 36px;
`;

const WelcomeScreen = ({
  navigation,
  route,
}: StackScreenProps<RootStackParamList, 'Welcome'>) => {
  const productId = route?.params?.productId;

  Logger(`Product: ${productId}`);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <WelcomeView>
      <LogoImage
        source={require('../../assets/images/Waffle_Logo_RGB_DarkBlue.png')}
      />
      <WaffleHighlightButton
        name={'Get a Quote'}
        onPress={() => navigation.navigate(StartQuoteScreenName)}
      />

      <Spacer y={2} />

      <WaffleHighlightButton
        name={'Login'}
        onPress={() => navigation.navigate(LoginScreenName)}
      />
    </WelcomeView>
  );
};

export default WelcomeScreen;
