import React, { useLayoutEffect } from 'react';
import { StepMessage } from '../Components/StepMessage';
import { StackScreenProps } from '@react-navigation/stack';
import {
  ProductChooserScreenName,
  RootStackParamList,
} from '../../screen-config';

const Step1Screen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Step 1'>) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <></>,
      gestureEnabled: false,
    });
  }, [navigation]);

  return (
    <StepMessage
      text={`Let's select your coverage`}
      onPress={async () => navigation.navigate(ProductChooserScreenName)}
    />
  );
};

export default Step1Screen;
