import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../../../screen-config';
import { StartQuoteProps } from '../StartQuote';

export class DisplayText {
  static readonly EnterYourZip =
    "Enter your zip code to see what's available in your area!";
  static readonly TheYouInsurance = 'The You Insurance';
}

export type StartQuoteViewProps = Pick<
  StartQuoteProps,
  'client' | 'productId'
> & {
  navigation: StackNavigationProp<RootStackParamList>;
};
