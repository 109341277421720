import React, { useState } from 'react';
import { Platform, StyleProp, TextStyle, ViewStyle } from 'react-native';
import { ErrorText } from '../../Components/ErrorText';
import { Spacer } from '../../Components/SimpleComponents';
import { WaffleButtonAsync } from '../../Components/WaffleButtonAsync';
import WaffleTextInput from '../../Components/WaffleTextInput';
import { useUserTracking } from '../../../lib/user-tracking/UserTrackingProvider';
import { getTraitsFromUrl } from '../../../lib/user-tracking/get-traits-from-url';
import { createTelemetryEntry } from '../../../lib/telemetry';
import { Logger } from '../../Helper/Logger';
import AuthenticationService from '../../Helper/AuthenticationService';
import { getStateFromZip } from '../../Helper/MiscFunctions';
import { useAuthContext } from '../AuthProvider';
import { ApolloClient } from '@apollo/client';
import { WaffleTextFamily } from '../../Components/WaffleTextSatoshi';
import { UpdateUserTraitsProps } from '../../../lib/user-tracking/UpdateUserTraitsProps';
import AsyncStorage from '@react-native-async-storage/async-storage';

const getOrRehydrateTraits = async (): Promise<
  UpdateUserTraitsProps | undefined
> => {
  const TRAIT_KEY = 'TRAITS_KEY';

  let traits = getTraitsFromUrl();

  if (!traits) {
    const jsonValue = await AsyncStorage.getItem(TRAIT_KEY);

    traits = jsonValue ? JSON.parse(jsonValue) : undefined;
  }

  if (traits) {
    await AsyncStorage.setItem(TRAIT_KEY, JSON.stringify(traits));
  }

  return traits;
};

export type StartQuoteProps = {
  client: ApolloClient<object>;
  productId: string;
  textInputStyle: StyleProp<TextStyle>;
  submitBtnStyle: ViewStyle;
  submitBtnTxtStyle: TextStyle;
};
const StartQuote = (props: StartQuoteProps): React.ReactElement => {
  const [zip, setZip] = useState<string>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const userTracking = useUserTracking();
  const isValidZip = getStateFromZip(zip) !== null;
  const { setIsSignedIn, token } = useAuthContext();
  const {
    client,
    productId,
    textInputStyle,
    submitBtnStyle,
    submitBtnTxtStyle,
  } = props;

  // token for getAuthContext.
  // if token (and maybe user) is set:
  //    if product ID is set, and customer doesn't have that product:
  //      create draft and continue.
  //    else
  //      go to product chooser (perhaps with message about already having product?)
  const onSubmitEditing =
    Platform.OS === 'web'
      ? () => {
          isValidZip && getStarted();
        }
      : undefined;

  const getStarted = async () => {
    await setSubmitting(true);
    const userTraits = await getOrRehydrateTraits();
    const { token, message, user } =
      await AuthenticationService.SignInGuestUser(
        client,
        zip,
        userTraits,
        productId
      );

    if (token) {
      createTelemetryEntry(user.id);
      userTracking?.linkAnonymousUserToCreatedUser(user.id, {
        state: user.state,
        zip: user.zip,
        ...userTraits,
      });
      Logger(`response token=${token}`);
    }

    if (!token && message) {
      await setSubmitting(false);
      setError(message);
      return;
    }

    setIsSignedIn(token, user, true);
  };
  return (
    <>
      <WaffleTextInput
        onChangeText={setZip}
        otherProps={{
          autoFocus: true,
          enablesReturnKeyAutomatically: true,
          autoCompleteType: 'postal-code',
          keyboardType: 'number-pad',
          textContentType: 'postalCode',
          onSubmitEditing,
        }}
        textInputStyle={[
          {
            fontFamily: WaffleTextFamily.Regular,
            color: '#292929',
            borderRadius: 15,
          },
          textInputStyle,
        ]}
        placeholder={'Zip'}
      />
      {error && <ErrorText>{error}</ErrorText>}
      <Spacer y={2} />
      <WaffleButtonAsync
        style={{
          ...{
            width: 269,
            borderRadius: 15,
          },
          ...submitBtnStyle,
        }}
        textStyle={{
          ...{ fontFamily: WaffleTextFamily.Medium, textTransform: 'none' },
          ...submitBtnTxtStyle,
        }}
        name={'Start Quote'}
        disabled={!isValidZip || submitting}
        onPress={async () => isValidZip && (await getStarted())}
      />
    </>
  );
};

export default StartQuote;
