import { useApolloClient, useQuery, useMutation } from '@apollo/client';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Platform, View } from 'react-native';
import styled from 'styled-components/native';
import * as yup from 'yup';
import {
  DRAFT_POLICIES,
  UPDATE_GUEST_USER_DETAILS,
  UPDATE_POLICY_ANSWERS_AND_REPRICE,
  VALIDATE_USER_ADDRESS,
} from '../../GraphQL/Waffle/Queries';
import WaffleText from '../../Components/WaffleText';
import { PlainView, Spacer } from '../../Components/SimpleComponents';
import { ErrorText } from '../../Components/ErrorText';
import {
  FirstNameIconInput,
  firstNameValidator,
} from '../../Components/CreateAccount/FirstNameInput';
import {
  LastNameIconInput,
  lastNameValidator,
} from '../../Components/CreateAccount/LastNameInput';
import { CityIconInput } from '../../Components/CreateAccount/CityInput';
import { StateIconInput } from '../../Components/CreateAccount/StateInput';
import { PostalIconInput } from '../../Components/CreateAccount/PostalInput';
import { cloneObj } from '../../Helper/MiscFunctions';
import {
  addressValidators,
  AutoCompleteAddress,
  validateAddressMessage,
} from '../../Components/CreateAccount/AutoCompleteAddress';
import { StateCodes } from '../../Constants/States';
import { WaffleButtonAsync } from '../../Components/WaffleButtonAsync';
import DateInput, {
  maxBirthDate,
  minBirthDate,
} from '../../Components/DateInput';
import {
  HorizontalSpread,
  VerticalCenter,
} from '../../Components/SimpleLayouts';
import { PhoneIconInput } from '../../Components/CreateAccount/PhoneInput';
import { hasGeo } from '../../Helper/FeatureFlags';
import GeoService from '../../Helper/GeoService';
import { Logger } from '../../Helper/Logger';
import { StackScreenProps } from '@react-navigation/stack';
import { RootStackParamList } from '../../../screen-config';
import {
  GetDraftUserPolicies,
  UpdateGuestUserDetails,
  UpdateGuestUserDetailsVariables,
  UpdatePolicyAnswerAndReprice,
  UpdatePolicyAnswerAndRepriceVariables,
  ValidateUserAddress,
  ValidateUserAddress_validateAddress_suggestions,
  ValidateUserAddressVariables,
} from '../../../../operation-result-types';
import { Address, UserState } from '../../Helper/UserHelpers';
import { SuggestedAddressPopup } from '../../Components/SuggestedAddressPopup';
import { OrangeHeaderImage } from '../../Components/HeaderNavTitle.web';
import { ContentUnified, ScrollingBlock } from '../../Components/ContentShared';
import { isFullWeb, isNativeOrMobileWeb } from '../../Helper/DeviceHelper';
import { useAuthContext } from '../AuthProvider';
import { SuggestedCoverageNextNavigation } from '../../Components/DesignPolicy/SuggestedCoverageShared';
import { HasRentersPolicy, IsRentalPolicy } from '../../Helper/PolicyHelper';
import {
  ProductCyber,
  ProductPet,
  ProductRenters,
} from '../../Constants/Products';
import { LoadingUnified } from '../../Components/LoadingUnified';
import { KeyboardViewMaybe } from '../../Components/KeyboardViewMaybe';
import AwesomeIcon from 'react-native-vector-icons/FontAwesome';
import { PolicyRentersAnswers } from '../../../component-config';
import { WaffleOrange, WindowWidth } from '../../Constants/Style';
import { TextIconInput } from '../../Components/TextIconInput';
import { HeaderBox } from './HeaderBox';
import { CollapsibleBox } from './CollapsibleBox';
import { RentalAdditionalInterest } from '../../Components/RentalAdditionalInterest';
import { useUserTracking } from '../../../lib/user-tracking/UserTrackingProvider';
import { isMobileSafari } from '../../Helper/DeviceHelper.web';

const Horizontal = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const NoteText = styled(WaffleText)`
  font-size: 14px;
  font-weight: 400;
  color: #787878;
  text-align: center;
`;

const dateOfBirthValidator = yup
  .string()
  .matches(/\d{4}-\d{2}-\d{2}/)
  .required(); // todo : validate date
const phoneValidator = yup.string().min(10).required();

const validatorValueMessage = (validator: any, value: string, label: string) =>
  validator.isValidSync(value) ? null : `Please enter your ${label}`;

const validateForm = (state: UserState) => {
  const errors: UserState = {
    // email: validatorValueMessage(EmailValidator, state.email, 'email'),
    firstName: validatorValueMessage(
      firstNameValidator,
      state.firstName,
      'first name'
    ),
    lastName: validatorValueMessage(
      lastNameValidator,
      state.lastName,
      'last name'
    ),
    dateOfBirth: validatorValueMessage(
      dateOfBirthValidator,
      state.dateOfBirth,
      'birth date'
    ),
    address: validateAddressMessage(state),
    phone: validatorValueMessage(phoneValidator, state.phone, 'phone number'),
  };

  return errors;
};

const schema = yup.object().shape({
  // email: EmailValidator,
  firstName: firstNameValidator,
  lastName: lastNameValidator,
  dateOfBirth: dateOfBirthValidator,
  phone: phoneValidator,
  referralCode: yup.string(),
  ...addressValidators,
});

// todo : lock state!  use different search for address!

const TITLE = 'Update Information';

const UpdateGuestUserScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Update Guest User'>) => {
  const client = useApolloClient();
  // const [reprice] = useMutation<RepriceDraftPolicies>(REPRICE_DRAFT_POLICIES);
  const [submissionError, setSubmissionError] = useState();
  const [state, setState] = useState<UserState>({
    // email: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    address: undefined,
    address2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    referralCode: '',
    validatedAddress: true,
    completedAddress: {},
    rawAddress: '',
  });
  const [answerInputs, setAnswerInputs] = useState<PolicyRentersAnswers>();
  const [checkAddress] = useMutation<
    ValidateUserAddress,
    ValidateUserAddressVariables
  >(VALIDATE_USER_ADDRESS);
  const [errors, setErrors] = useState<UserState>({});
  const [valid, setValid] = useState(false);
  const [hideResults, setHideResults] = useState(true);
  const [addressSuggestion, setAddressSuggestion] =
    useState<ValidateUserAddress_validateAddress_suggestions>();
  const width = isFullWeb() ? 450 : Math.max(WindowWidth() - 80, 300);
  const collapsibleBodyWidth = width - 18;

  const userTracking = useUserTracking();
  const { isGuest, refreshUser, user } = useAuthContext();
  const { data, loading } = useQuery<GetDraftUserPolicies>(DRAFT_POLICIES, {
    fetchPolicy: 'network-only',
  });
  const policies = data?.draftUserPolicies ?? [];
  const hasRenters = HasRentersPolicy(policies);
  const rentersPolicy = policies?.find((p) => IsRentalPolicy(p));
  const answers = rentersPolicy?.answerInputs;
  const products = policies.map((p) => p.productId);
  const promptDateOfBirth =
    products.includes(ProductCyber) ||
    products.includes(ProductRenters) ||
    products.includes(ProductPet);

  useEffect(() => {
    setState({
      ...state,
      // email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      dateOfBirth: user.dateOfBirth,
      address: user.address,
      address2: user.address2,
      city: user.city,
      state: user.state,
      zip: user.zip,
    });
  }, [user]);

  useEffect(() => {
    if (answers?.__typename === 'PolicyRentersAnswer') {
      setAnswerInputs(answers);
    }
  }, [answers]);

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <></>,
      headerBackTitleVisible: false,
    });
  }, [navigation]);

  const onChange = (obj: UserState) => {
    // wrap in useCallback
    const newState = { ...state, ...obj };
    const isValid =
      schema.isValidSync(newState) && !!StateCodes[newState.state];

    setState(newState);
    setValid(isValid);

    // Logger(`onChange: state=${JSON.stringify(state)} newState=${JSON.stringify(newState)} isValid=${isValid}`);

    return newState;
  };

  const onAddressFieldChange = (obj: UserState) =>
    onChange({
      ...obj,
      validatedAddress: false,
    });

  const setAddress = (address: Address) => {
    return onChange({
      address: `${address.houseNumber} ${address.street}`,
      city: address.city,
      state: address.state,
      zip: address.postalCode,
      validatedAddress: true,
      completedAddress: cloneObj(address),
      rawAddress: '',
    });
  };

  const validateValue = (
    field: keyof UserState,
    value: string,
    validator: any,
    label: string
  ) =>
    setErrors({
      ...errors,
      [field]: validatorValueMessage(validator, value, label),
    });

  const validateAddress = (st: UserState) =>
    setErrors({ ...errors, address: validateAddressMessage(st) });

  const isValidAsync = async () => {
    setErrors(validateForm(state));

    if (!valid) {
      return false;
    }

    const {
      data: { validateAddress },
    } = await checkAddress({
      variables: {
        input: {
          address: state.address,
          address2: state.address2,
          city: state.city,
          state: state.state,
          zip: state.zip,
        },
      },
    });

    if (!validateAddress.isValid) {
      if (validateAddress?.suggestions?.length > 0) {
        setAddressSuggestion(validateAddress.suggestions[0]);
      } else {
        setErrors((e) => ({
          ...e,
          address:
            validateAddress?.message ??
            'The address does not appear to be valid',
        }));
      }
      // Logger(`addresses: ${JSON.stringify(validateAddress)}`);

      return false;
    }

    return true;
  };

  const updateAnswerInputs = async () => {
    if (hasRenters && rentersPolicy) {
      const answer_inputs = JSON.stringify(answerInputs);

      await client.mutate<
        UpdatePolicyAnswerAndReprice,
        UpdatePolicyAnswerAndRepriceVariables
      >({
        mutation: UPDATE_POLICY_ANSWERS_AND_REPRICE,
        variables: {
          input: {
            id: rentersPolicy.id,
            answer_inputs,
          },
        },
      });
    }
  };

  const updateUser = async () => {
    const userInput = {
      // email: state.email.trim(),
      address: state.address.trim(),
      address2: state.address2?.trim(),
      city: state.city.trim(),
      state: state.state.trim(),
      zip: state.zip.trim(),
      dateOfBirth: state.dateOfBirth,
      firstName: state.firstName.trim(),
      lastName: state.lastName.trim(),
      phone: state.phone.trim(),
    };
    await client.mutate<
      UpdateGuestUserDetails,
      UpdateGuestUserDetailsVariables
    >({
      mutation: UPDATE_GUEST_USER_DETAILS,
      variables: {
        input: userInput,
      },
    });

    userTracking?.updateUserTraits(userInput);
    await refreshUser();

    // Refresh the state
    // if (state.email.trim() !== user.email) {
    //   await reprice();
    // }
  };

  const onPick = async (address) => {
    const newState = { ...state, ...address };

    // User performed a correction, so update state, clear errors, and proceed
    await setErrors({});
    await setState(newState);
    await setAddressSuggestion(undefined);

    await updateAnswerInputs();
    await updateUser();

    await SuggestedCoverageNextNavigation(
      navigation,
      client,
      isGuest,
      policies.map((p) => p.productId)
    );
  };

  useEffect(() => {
    if (hasGeo()) {
      GeoService.startServiceIfNecessary(client).then((_) =>
        Logger('Done starting GeoService')
      );
    }
  }, []);

  const onSubmitAsync = async () => {
    if (await isValidAsync()) {
      await updateAnswerInputs();
      await updateUser();

      await SuggestedCoverageNextNavigation(
        navigation,
        client,
        isGuest,
        policies.map((p) => p.productId)
      );
    }
  };

  if (loading) {
    return <LoadingUnified title={TITLE} />;
  }

  // Logger(`onAnswerInputs: state=${JSON.stringify(answerInputs)}`);

  return (
    <ContentUnified
      title={TITLE}
      image={OrangeHeaderImage}
      ChildrenWrapper={ScrollingBlock}
      contentStyle={{ backgroundColor: 'white' }} // This is temporary until this screen gets redesigned
      addingProduct={true}>
      <KeyboardViewMaybe>
        <VerticalCenter>
          <>
            {Platform.OS === 'ios' && <Spacer y={2.5} />}
            <Spacer y={2.5} />
            <WaffleText
              style={{
                fontSize: 36,
                fontWeight: '700',
                color: '#373737',
              }}>
              Almost there!
            </WaffleText>
            <Spacer y={0.5} />
          </>

          <WaffleText
            style={{
              fontSize: 15,
              fontWeight: '400',
              color: '#373737',
              width: isNativeOrMobileWeb() ? 275 : undefined,
              textAlign: 'center',
            }}>
            We just need a few more things...
          </WaffleText>

          <ErrorText>{submissionError}</ErrorText>

          {/*{!user?.email && (*/}
          {/*  <>*/}
          {/*    <UserNameInput*/}
          {/*      label={'Email'}*/}
          {/*      value={state?.email}*/}
          {/*      errorFooterText={errors.email}*/}
          {/*      onChangeText={(email: string) => onChange({ email })}*/}
          {/*      containerStyle={{ width }}*/}
          {/*      onValidate={(value) =>*/}
          {/*        validateValue('email', value, EmailValidator, 'email address')*/}
          {/*      }*/}
          {/*    />*/}

          {/*    <Spacer y={1} />*/}
          {/*  </>*/}
          {/*)}*/}

          <HeaderBox title={'General'} width={width} />

          <Spacer y={2.5} />

          <HorizontalSpread
            testID="name"
            style={{ width, alignItems: 'flex-start' }}>
            <FirstNameIconInput
              onChangeText={(firstName: string) => onChange({ firstName })}
              value={state.firstName}
              errorFooterText={errors.firstName}
              onValidate={(value) =>
                validateValue(
                  'firstName',
                  value,
                  firstNameValidator,
                  'first name'
                )
              }
            />

            <Spacer x={1} />

            <LastNameIconInput
              onChangeText={(lastName: string) => onChange({ lastName })}
              value={state.lastName}
              errorFooterText={errors.lastName}
              onValidate={(value) =>
                validateValue('lastName', value, lastNameValidator, 'last name')
              }
            />
          </HorizontalSpread>

          {promptDateOfBirth && (
            <>
              <Spacer y={1} />

              <HorizontalSpread testID="dateOfBirth">
                <DateInput
                  value={state.dateOfBirth}
                  placeholder={'Birth date    YYYY-MM-DD'}
                  onChangeText={(dateOfBirth: string) =>
                    onChange({ dateOfBirth })
                  }
                  containerStyle={{ width }}
                  errorFooterText={errors.dateOfBirth}
                  onValidate={(value) =>
                    validateValue(
                      'dateOfBirth',
                      value,
                      dateOfBirthValidator,
                      'birth date'
                    )
                  }
                  initialDate={maxBirthDate()}
                  minimumDate={minBirthDate()}
                  maximumDate={maxBirthDate()}
                />
              </HorizontalSpread>
            </>
          )}

          <PlainView testID="address" style={{ width }}>
            <AutoCompleteAddress
              hideResults={Platform.OS !== 'ios' && hideResults}
              value={state.address}
              state={state.state}
              onChoose={(address: any) => validateAddress(setAddress(address))}
              onFocus={() => setHideResults(false)}
              onBlur={() => {
                setTimeout(() => setHideResults(true), 500); // delay on hiding results when we lose focus
                validateAddress(state);
              }}
              onChangeText={(rawAddress: string) =>
                onAddressFieldChange({ address: rawAddress, rawAddress })
              }
            />

            <HorizontalSpread style={{ width }}>
              <TextIconInput
                placeholder={'Suite, apartment, etc (opt)'}
                leftIcon={
                  <AwesomeIcon
                    name={'building'}
                    color={WaffleOrange}
                    size={23}
                  />
                }
                value={state.address2}
                otherProps={{
                  autoCompleteType: 'address-line2',
                  textContentType: 'streetAddressLine2',
                  editable: true,
                }}
                // this isn't a standard address change, as it doesn't taint
                // the address.
                onChangeText={(address2: string) => onChange({ address2 })}
                onBlur={() => validateAddress(state)}
              />
            </HorizontalSpread>

            <HorizontalSpread style={{ width }}>
              <CityIconInput
                value={state.city}
                onChangeText={(city: string) => onAddressFieldChange({ city })}
                otherProps={{ editable: true }}
                onBlur={() => validateAddress(state)}
              />

              <Spacer x={1} />

              <StateIconInput
                value={state.state}
                onChangeText={(state: string) =>
                  onAddressFieldChange({ state })
                }
                otherProps={{ editable: false }}
                onBlur={() => validateAddress(state)}
              />
            </HorizontalSpread>

            <Horizontal>
              <PostalIconInput
                value={state.zip}
                onChangeText={(zip: string) => onAddressFieldChange({ zip })}
                otherProps={{ editable: true }}
                errorFooterText={errors.address} // Yes, we consolidate all validation under "address"
                onBlur={() => validateAddress(state)}
              />
            </Horizontal>
          </PlainView>

          <PlainView style={{ width, height: 80 }}>
            <PhoneIconInput
              value={state.phone}
              onChangeText={(phone: string) => onChange({ phone })}
              errorFooterText={errors.phone}
              onValidate={(value) =>
                validateValue('phone', value, phoneValidator, 'phone number')
              }
              containerStyle={{ width }}
              containerProps={{ testID: 'phoneNbr' }}
              leftIcon={
                <AwesomeIcon name={'phone'} color={WaffleOrange} size={23} />
              }
            />
          </PlainView>

          <Spacer y={3} />

          {hasRenters && (
            <>
              <HeaderBox title={'Extras (Optional)'} width={width} />

              <Spacer y={3} />

              <CollapsibleBox
                title={`Add Apartment's Management Company`}
                width={width}>
                <VerticalCenter
                  style={{
                    width: collapsibleBodyWidth,
                    height: Platform.OS === 'ios' ? 600 : undefined,
                  }}>
                  <RentalAdditionalInterest
                    width={collapsibleBodyWidth}
                    answerInputs={answerInputs?.AdditionalInterest}
                    setAnswerInputs={(AdditionalInterest) =>
                      setAnswerInputs({
                        ...answerInputs,
                        AdditionalInterest,
                      })
                    }
                  />

                  <Spacer y={1} />

                  <NoteText>
                    Examples include your apartment’s management company.
                  </NoteText>
                </VerticalCenter>
              </CollapsibleBox>

              <Spacer y={3} />

              <CollapsibleBox
                title={`Add Spouse, Partner, or Roommate`}
                width={width}>
                <VerticalCenter
                  style={{
                    width: collapsibleBodyWidth,
                  }}>
                  <Horizontal
                    testID="addtlInsuredName"
                    style={{
                      width: collapsibleBodyWidth,
                      alignItems: 'flex-start',
                    }}>
                    <FirstNameIconInput
                      onChangeText={(FirstName: string) =>
                        setAnswerInputs((existing) => ({
                          ...existing,
                          AdditionalInsured: {
                            ...existing.AdditionalInsured,
                            FirstName,
                          },
                        }))
                      }
                      value={answerInputs?.AdditionalInsured?.FirstName}
                      otherProps={{
                        keyboardType: 'name-phone-pad',
                        autoCompleteType: 'off',
                        textContentType: 'none',
                      }}
                    />

                    <Spacer x={1} />

                    <LastNameIconInput
                      onChangeText={(LastName: string) =>
                        setAnswerInputs((existing) => ({
                          ...existing,
                          AdditionalInsured: {
                            ...existing.AdditionalInsured,
                            LastName,
                          },
                        }))
                      }
                      value={answerInputs?.AdditionalInsured?.LastName}
                      otherProps={{
                        keyboardType: 'name-phone-pad',
                        autoCompleteType: 'off',
                        textContentType: 'none',
                      }}
                    />
                  </Horizontal>
                  <Spacer y={1} />

                  <NoteText>
                    Specify someone that lives with you to be added to the
                    policy. Examples include your spouse, partner, or roommate.
                    If you need to add more than one please contact us at
                    contact@waffle-labs.com.
                  </NoteText>
                </VerticalCenter>
              </CollapsibleBox>

              <Spacer y={3} />
            </>
          )}

          <View style={{ alignItems: 'center' }}>
            <WaffleButtonAsync
              onPress={onSubmitAsync}
              name={'Continue'}
              style={{ width }}
            />

            <Spacer y={1} />
          </View>

          <SuggestedAddressPopup
            address={state}
            suggestion={addressSuggestion}
            onDismiss={() => setAddressSuggestion(null)}
            onPick={onPick}
          />
        </VerticalCenter>
        {isMobileSafari() ? <Spacer y={9} /> : <Spacer y={3} />}
      </KeyboardViewMaybe>
    </ContentUnified>
  );
};

export default UpdateGuestUserScreen;
