import React from 'react';
import styled from 'styled-components';
import styledNative from 'styled-components/native';
import { HorizontalPacked } from '../../Components/SimpleLayouts';
import StartQuote from './StartQuote';
import style from '../../Constants/Style';
import { Spacer } from '../../Components/SimpleComponents';
import {
  RegularText,
  VCSText,
  WaffleBackgroundImage,
  WaffleLogoImage,
} from './components/Shared';
import {
  DisplayText,
  StartQuoteViewProps,
} from './components/start-quote.constants';
import AlreadyHaveAccount from './components/AlreadyHaveAccount';

const LeftBackgroundLogoContainer = styled.div`
  width: 100%;
  max-width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const WaffleBackgroundLogo = styledNative.Image`
  width: 95%;
  height: 70%;
`;

// Justify-content screws up overflow-y for some reason...
const StartQuoteVertical = styled.div`
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  align-items: flex-start;
  display: flex;
`;

const StartQuoteDesktopView = (
  props: StartQuoteViewProps
): React.ReactElement => {
  const { client, navigation, productId } = props;
  return (
    <HorizontalPacked style={[style.fullWidth, style.fullHeight]}>
      <LeftBackgroundLogoContainer style={{ backgroundColor: 'black' }}>
        <WaffleBackgroundImage
          source={require('../../../assets/images/Grunge_Waffle_Desktop.webp')}
          resizeMode={'cover'}
        />
        <WaffleBackgroundLogo
          source={require('../../../assets/images/Waffle IconMark - white .svg')}
          resizeMode={'contain'}
        />
      </LeftBackgroundLogoContainer>
      <Spacer x={8} />
      <StartQuoteVertical>
        <WaffleLogoImage
          source={require('../../../assets/images/Waffle Rebrand 2022 - Full Logo.webp')}
          resizeMode={'contain'}
        />
        <Spacer y={5} />
        <VCSText style={{ fontSize: 62 }}>
          {DisplayText.TheYouInsurance}
        </VCSText>
        <Spacer y={5} />
        <RegularText
          style={{
            fontSize: 26,
            lineHeight: 35,
            maxWidth: '440px',
          }}>
          {DisplayText.EnterYourZip}
        </RegularText>
        <StartQuote
          client={client}
          productId={productId}
          textInputStyle={{ fontSize: 24, height: '54px', width: '350px' }}
          submitBtnStyle={{ height: '54px' }}
          submitBtnTxtStyle={{ fontSize: 22 }}
        />
        <Spacer y={5} />

        <AlreadyHaveAccount
          displayTextStyle={{ fontSize: 18 }}
          signInBtnStyle={{
            width: '269px',
            height: '54px',
          }}
          signInTxtStyle={{ fontSize: 22 }}
          navigation={navigation}
        />
      </StartQuoteVertical>
    </HorizontalPacked>
  );
};

export default StartQuoteDesktopView;
