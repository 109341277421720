import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { StackNavigationProp, StackScreenProps } from '@react-navigation/stack';
import {
  HomeScreenName,
  ProductChooserScreenName,
  RootStackParamList,
  UpdateGuestUserScreenName,
} from '../../screen-config';
import {
  Image,
  MobileWebWrapper,
  PlainView,
  Spacer,
} from '../Components/SimpleComponents';
import { View } from 'react-native';
import ProgressBar from '../Components/ProgressBar';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import SuggestedCoverageCustomizeTab from '../Components/DesignPolicy/SuggestedCoverageCustomizeTab';
import SuggestedCoverageSummaryTab from '../Components/DesignPolicy/SuggestedCoverageSummaryTab';
import SuggestedCoverageCoverageTab from '../Components/DesignPolicy/SuggestedCoverageCoverageTab';
import {
  DarkText,
  LightText,
  WaffleDarkBlue,
  WaffleExtraDark,
  WaffleLightBlue,
  WaffleOrange,
  White,
  WindowWidth,
} from '../Constants/Style';
import {
  GetDraftUserPolicies,
  GetDraftUserPolicies_draftUserPolicies,
  LocalUser,
  LocalUser_user,
  RepriceDraftPolicies,
} from '../../../operation-result-types';
import {
  DRAFT_POLICIES,
  GET_LOCAL_USER,
  REPRICE_DRAFT_POLICIES,
  UPDATE_USER_STATE,
} from '../GraphQL/Waffle/Queries';
import { Policy } from '../../component-config';
import {
  HorizontalPacked,
  HorizontalSpread,
  VerticalCenter,
  VerticalSpread,
} from '../Components/SimpleLayouts';
import styled from 'styled-components/native';
import WaffleText from '../Components/WaffleText';
import {
  HasHomePolicy,
  HasPetPolicy,
  IsHomePolicy,
  PolicyMidFrequencyLabel,
  TravelPolicyCFARValue,
} from '../Helper/PolicyHelper';
import { WaffleButtonAsync } from '../Components/WaffleButtonAsync';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { PolicyItemsDisplay } from '../PolicyItemsDisplay';
import { Markup } from '../Components/Markup';
import { useNavigation } from '@react-navigation/native';
import { Logger } from '../Helper/Logger';
import {
  continueTitle,
  continueVerbiage,
  CoveragePopup,
  extractPolicyProductMessages,
  onReFetch,
  PoweredByBlurb,
  SuggestedCoverageNextNavigation,
} from '../Components/DesignPolicy/SuggestedCoverageShared';
import {
  IsIncompleteUser,
  RefreshUserState,
  UpdateUserStateOnServer,
} from '../Helper/UserHelpers';
import {
  USER_STATE_ACCOUNT_DETAILS_BEFORE_PAY,
  USER_STATE_PRODUCT_CHOOSER,
} from '../Helper/NavigationService';
import { Updating } from '../Updating';
import {
  ContentUnified,
  MainContentContainer,
} from '../Components/ContentShared';
import { OrangeHeaderImage } from '../Components/HeaderNavTitle.web';
import { priceFmt } from '../Helper/MiscFunctions';
import {
  ApolloClient,
  useQuery,
  useApolloClient,
  useMutation,
} from '@apollo/client';
import { CancelAddProductPopup } from '../Components/CancelAddProductPopup';
import {
  isFullWeb,
  isMobileWeb,
  isNativeOrMobileWeb,
} from '../Helper/DeviceHelper';
import { useAuthContext } from './AuthProvider';
import { ErrorText } from '../Components/ErrorText';

const Tab = createMaterialTopTabNavigator();

export type SuggestedCoverageState = {
  tab: number;
  policyCount: number;
  currentPolicy?: Policy;
  updating: boolean;
  confirmPopup: boolean;
  updateMessages: Record<string, string>;
};

export type SuggestedSetState = Dispatch<
  SetStateAction<SuggestedCoverageState>
>;

type SuggestedCoverageContextState = {
  state: SuggestedCoverageState;
  setState: SuggestedSetState;
};

export const SuggestedCoverageContext =
  React.createContext<SuggestedCoverageContextState>(null);

const FooterView = styled(VerticalSpread)`
  background-color: ${WaffleDarkBlue};
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const SimpleFooterView = styled(FooterView)`
  height: 120px;
`;

const Price = styled(WaffleText)`
  font-size: 32px;
  font-weight: 500;
  color: white;
  line-height: 43px;
`;

const Freq = styled(WaffleText)`
  font-size: 14px;
  font-weight: 500;
  color: white;
  line-height: 28px;
`;

const onTabPress = (navigation, route) => {
  route?.params?.scrollToTop();
};

function TabNavigator({
  policy,
}: {
  policy: GetDraftUserPolicies_draftUserPolicies | undefined;
}) {
  const listener = ({ navigation, route }) => ({
    tabPress: () => onTabPress(navigation, route),
  });

  return (
    <Tab.Navigator
      tabBarOptions={{
        activeTintColor: WaffleLightBlue,
        inactiveTintColor: WaffleExtraDark,
        labelStyle: {
          fontSize: 14,
          lineHeight: 18,
          textTransform: 'none',
        },
      }}>
      <Tab.Screen
        name="Summary"
        component={SuggestedCoverageSummaryTab}
        listeners={listener}
      />
      {!IsHomePolicy(policy) && (
        <>
          <Tab.Screen
            name="Customize"
            component={SuggestedCoverageCustomizeTab}
            listeners={listener}
          />
          <Tab.Screen
            name="Coverage"
            component={SuggestedCoverageCoverageTab}
            listeners={listener}
          />
        </>
      )}
    </Tab.Navigator>
  );
}

const nextScreen = () => (existing: SuggestedCoverageState) => {
  return {
    ...existing,
    tab: Math.min(existing.policyCount - 1, existing.tab + 1),
    confirmPopup:
      existing.policyCount === 1 || existing.tab === existing.policyCount - 1,
  };
};

const confirmPopup =
  (confirmPopup: boolean) => (existing: SuggestedCoverageState) => {
    return {
      ...existing,
      confirmPopup,
    };
  };

const PriceFooterButtons = ({ policyCount }: PriceFooterProps) => {
  const client = useApolloClient();
  const {
    data: { user },
  } = useQuery<LocalUser>(GET_LOCAL_USER);
  const [popup, setPopup] = useState(false);
  const primaryText = 'Next';
  const secondaryText = policyCount === 1 ? 'Cancel' : 'Prev';
  const { state, setState } = useContext(SuggestedCoverageContext);
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  const previousScreen =
    (client: ApolloClient<object>, user: LocalUser_user) =>
    (existing: SuggestedCoverageState) => {
      if (existing.tab === 0) {
        // we need to cancel!
        cancelAddProduct(client, user);
      }

      return {
        ...existing,
        tab: Math.max(0, existing.tab - 1),
      };
    };

  const cancelAddProduct = async (
    client: ApolloClient<object>,
    user: LocalUser_user
  ) => {
    const isComplete = user?.userState === 'COMPLETE';

    if (isComplete) {
      navigation.navigate(HomeScreenName);
    } else {
      Logger(
        `user state not complete.  reverting user state to USER_STATE_PRODUCT_CHOOSER`
      );
      await client.mutate({
        mutation: UPDATE_USER_STATE,
        variables: {
          state: USER_STATE_PRODUCT_CHOOSER,
        },
      });

      navigation.navigate(ProductChooserScreenName);
    }
  };

  // Investigate this function
  const previousOrCancel = async () => {
    if (state.tab === 0) {
      setPopup(true);
    } else {
      await setState(previousScreen(client, user));
    }
  };

  return (
    <HorizontalPacked>
      <WaffleButtonAsync
        disabled={state.updating}
        style={{
          width: 105,
          height: 43,
          backgroundColor: WaffleDarkBlue,
          borderColor: White,
          borderWidth: 1,
        }}
        disabledTextStyle={{
          color: White,
        }}
        onPress={previousOrCancel}
        name={secondaryText}
      />

      <Spacer x={0.5} />

      <WaffleButtonAsync
        disabled={state.updating}
        style={{
          width: 92,
          height: 43,
        }}
        disabledStyle={{
          backgroundColor: WaffleOrange,
        }}
        onPress={async () => await setState(nextScreen())}
        name={primaryText}
      />
      <Spacer x={1.5} />

      <CancelAddProductPopup user={user} popup={popup} setPopup={setPopup} />
    </HorizontalPacked>
  );
};

const SimplePriceFooter = ({
  updating,
  policyCount,
  policy,
}: PriceFooterProps) => {
  const freq = PolicyMidFrequencyLabel(policy);

  return (
    <SimpleFooterView>
      <Spacer y={5} />

      <HorizontalSpread style={{ width: '100%' }}>
        <HorizontalPacked
          style={{ alignItems: 'flex-end', opacity: updating ? 0.5 : 1.0 }}>
          <Spacer x={1.5} />
          <Price>${priceFmt(policy.price)}</Price>
          <Freq>{freq}</Freq>
        </HorizontalPacked>

        <PlainView style={{ position: 'relative' }}>
          <Updating
            isCompact={true}
            updating={updating}
            viewStyle={{ right: undefined, left: -10, top: -20 }}
          />
        </PlainView>

        <Spacer x={1} />

        <PriceFooterButtons policyCount={policyCount} policy={policy} />
      </HorizontalSpread>

      <Spacer y={4.5} />
    </SimpleFooterView>
  );
};

const SimplePriceFooterWeb = ({
  updating,
  policyCount,
  policy,
}: PriceFooterProps) => {
  const freq = PolicyMidFrequencyLabel(policy);

  return (
    <FooterView testID="coverageFooter">
      <HorizontalSpread style={{ width: '100%', padding: 28.5 }}>
        <HorizontalPacked style={{ alignItems: 'flex-end' }}>
          <Price>${priceFmt(policy.price)}</Price>
          <Freq>{freq}</Freq>
        </HorizontalPacked>

        <Updating
          isCompact={true}
          updating={updating}
          viewStyle={{ position: 'relative', top: 0, right: 0 }}
        />
        <PriceFooterButtons policyCount={policyCount} policy={policy} />
      </HorizontalSpread>
    </FooterView>
  );
};

const footerHeight = (productId: string) => {
  switch (productId) {
    case 'Pet':
      return 340;
    case 'Life':
      return 330;
    default:
      return 220;
  }
};

const ItemizedPriceFooter = ({
  policyCount,
  updating,
  policy,
}: PriceFooterProps) => {
  const [opened, setOpened] = useState(false);
  const freq = PolicyMidFrequencyLabel(policy);
  const height = opened ? footerHeight(policy.productId) : 120;
  const hasItemizedPrices = policy.items?.length > 1;
  const updatingOpacity = { opacity: updating ? 0.5 : 1.0 };

  return (
    <FooterView testID="coverageFooter" style={{ height }}>
      <Icon
        name={opened ? 'expand-more' : 'expand-less'}
        size={36}
        color={White}
        onPress={() => setOpened(!opened)}
      />

      {opened && (
        <>
          {hasItemizedPrices && (
            <>
              <PolicyItemsDisplay
                showTotal={true}
                policy={policy}
                updating={false}
                textStyle={{ color: White }}
                totalTextStyle={{ color: WaffleOrange }}
                viewStyle={{ width: 335, ...updatingOpacity }}
              />

              <Spacer y={1} />
            </>
          )}

          <PlainView style={{ width: 335 }}>
            <Markup
              linkPress={(url) => true}
              style={{
                paragraph: {
                  marginTop: 0,
                  marginBottom: 9,
                },
                body: {
                  fontSize: 14,
                  lineHeight: 20,
                  color: isFullWeb() ? DarkText : LightText,
                },
              }}>
              {policy.product.suggestedCoverageFooter}
            </Markup>
          </PlainView>

          <Spacer y={1} />
        </>
      )}

      <VerticalCenter style={{ width: '100%' }}>
        <HorizontalSpread style={{ width: '100%' }}>
          <HorizontalPacked
            style={{ alignItems: 'flex-end', ...updatingOpacity }}>
            <Spacer x={1.5} />
            <Price>${priceFmt(policy.price)}</Price>
            <Freq>{freq}</Freq>
          </HorizontalPacked>

          <Updating
            isCompact={true}
            updating={updating}
            viewStyle={{
              position: 'absolute',
              top: 15,
              right: WindowWidth() / 2 + 10,
            }}
          />

          <Spacer x={1} />

          <PriceFooterButtons policyCount={policyCount} policy={policy} />
        </HorizontalSpread>

        <Spacer y={4.5} />
      </VerticalCenter>
    </FooterView>
  );
};

const ItemizedPriceFooterWeb = ({
  policyCount,
  updating,
  policy,
}: PriceFooterProps) => {
  const [opened, setOpened] = useState(false);
  const freq = PolicyMidFrequencyLabel(policy);
  const hasItemizedPrices = policy.items?.length > 1;

  return (
    <FooterView testID="coverageFooter">
      <Icon
        name={opened ? 'expand-more' : 'expand-less'}
        size={20}
        color={White}
        onPress={() => setOpened(!opened)}
      />
      {opened && (
        <>
          {hasItemizedPrices && (
            <View style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
              <PolicyItemsDisplay
                showTotal={true}
                policy={policy}
                updating={false}
                textStyle={{ color: White }}
                totalTextStyle={{ color: WaffleOrange }}
                viewStyle={{ width: 335 }}
              />
            </View>
          )}

          <PlainView style={{ maxWidth: '90%', padding: 16 }}>
            <Markup
              linkPress={(url) => true}
              style={{
                paragraph: {
                  marginTop: 0,
                },
                body: {
                  fontSize: 14,
                  lineHeight: 20,
                  color: LightText,
                },
              }}>
              {policy.product.suggestedCoverageFooter}
            </Markup>
          </PlainView>
        </>
      )}

      <VerticalCenter style={{ width: '100%' }}>
        <HorizontalSpread
          style={{
            width: '100%',
            paddingBottom: 12,
            paddingLeft: 12,
            paddingRight: 12,
          }}>
          <HorizontalPacked style={{ alignItems: 'flex-end' }}>
            <Price>${priceFmt(policy.price)}</Price>
            <Freq>{freq}</Freq>
          </HorizontalPacked>

          <Updating
            isCompact={true}
            updating={updating}
            viewStyle={{
              position: 'absolute',
              top: 15,
              right: WindowWidth() / 2 + 10,
            }}
          />

          <PriceFooterButtons policyCount={policyCount} policy={policy} />
        </HorizontalSpread>
      </VerticalCenter>
    </FooterView>
  );
};

type PriceFooterProps = {
  policyCount: number;
  updating?: boolean;
  policy: Policy;
};

const PriceFooter = ({ policyCount, updating, policy }: PriceFooterProps) => {
  if (!policy) {
    return null;
  }

  if (policy.items?.length <= 1 && !policy.product?.suggestedCoverageFooter) {
    if (isFullWeb()) {
      return (
        <SimplePriceFooterWeb
          policyCount={policyCount}
          updating={updating}
          policy={policy}
        />
      );
    }

    return (
      <SimplePriceFooter
        policyCount={policyCount}
        updating={updating}
        policy={policy}
      />
    );
  }

  if (isFullWeb()) {
    return (
      <ItemizedPriceFooterWeb
        policyCount={policyCount}
        updating={updating}
        policy={policy}
      />
    );
  }

  return (
    <ItemizedPriceFooter
      policyCount={policyCount}
      updating={updating}
      policy={policy}
    />
  );
};

const SuggestedCoverageScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Suggested Coverage'>) => {
  const client = useApolloClient();
  const { data, loading } = useQuery<GetDraftUserPolicies>(DRAFT_POLICIES, {
    fetchPolicy: 'network-only',
  });
  const [reprice] = useMutation<RepriceDraftPolicies>(REPRICE_DRAFT_POLICIES);
  const [state, setState] = useState<SuggestedCoverageState>({
    tab: 0,
    policyCount: 0,
    updating: false,
    confirmPopup: false,
    updateMessages: {},
  });
  const { isGuest, refreshUser } = useAuthContext();
  const {
    data: { user },
  } = useQuery<LocalUser>(GET_LOCAL_USER);

  const policies = data?.draftUserPolicies ?? [];
  const policyCount = data?.draftUserPolicies?.length ?? 1;
  const currentPolicy = data?.draftUserPolicies?.[state.tab];
  const currentError = currentPolicy?.productId
    ? state.updateMessages[currentPolicy.productId]
    : undefined;

  const hasPet = HasPetPolicy(policies);
  const onlyHome = HasHomePolicy(policies);
  const hasCfar = TravelPolicyCFARValue(currentPolicy) === 'true';
  const popupTitle = continueTitle(hasPet, IsIncompleteUser(isGuest, user));
  const popupVerbiage = continueVerbiage(
    hasPet,
    hasCfar,
    onlyHome,
    IsIncompleteUser(isGuest, user)
  );
  const popupButtonText = hasCfar ? 'I understand' : "Yes, I'm ready";
  const Wrapper = isMobileWeb() ? MobileWebWrapper : MainContentContainer;

  const rePriceAndReFetch = () => {
    Logger(`DesignCoverageDetail: start initial pricing`);
    setState((st) => ({ ...st, updating: true }));

    reprice().then(({ data }) => {
      const updateMessages = data?.repriceDraftPolicies
        ? extractPolicyProductMessages(data.repriceDraftPolicies)
        : {};

      onReFetch(client).then(() => {
        setState((st) => ({ ...st, updateMessages, updating: false }));
        Logger(`DesignCoverageDetail: done pricing tab`);
      });
    });
  };

  const onContinue = async () => {
    await setState(confirmPopup(false));

    // If user is guest, then make them fill out more information
    if (IsIncompleteUser(isGuest, user)) {
      await UpdateUserStateOnServer(
        client,
        USER_STATE_ACCOUNT_DETAILS_BEFORE_PAY
      );

      await RefreshUserState(client);

      await navigation.navigate(UpdateGuestUserScreenName);

      return;
    }

    await SuggestedCoverageNextNavigation(
      navigation,
      client,
      isGuest,
      policies.map((p) => p.productId)
    );
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Waffle',
      headerTitle: () => (
        <VerticalCenter style={{ width: '100%' }}>
          <Image
            source={require('../../assets/images/waffleLogoTitle.png')}
            style={{ width: 118, height: 30 }}
          />
        </VerticalCenter>
      ),
      headerLeft: null,
      headerShown: isNativeOrMobileWeb(),
      headerStyle: {
        backgroundColor: 'white',
      },
      gestureEnabled: false,
    });
  }, [navigation]);

  useEffect(() => {
    // If we're a guest user, let's refresh the user, as it may be updated from product chat.
    if (isGuest) {
      refreshUser();
      // RefreshUserState(client).then((u) => Logger(`RefreshUserState updated`));
    }
  }, [client, isGuest]);

  useEffect(() => {
    if (loading) {
      return;
    }

    setState((existing) => ({ ...existing, currentPolicy, policyCount }));

    if (currentPolicy?.price === 0 || !currentPolicy) {
      rePriceAndReFetch();
    }
  }, [
    currentPolicy,
    currentPolicy?.price,
    currentPolicy?.productId,
    policyCount,
    loading,
  ]);

  return (
    <ContentUnified
      title={'Design your coverage'}
      image={OrangeHeaderImage}
      addingProduct={true}
      ChildrenWrapper={Wrapper}>
      {isFullWeb() && state.currentPolicy && (
        <VerticalCenter
          style={{ justifyContent: 'flex-start', position: 'relative' }}>
          <PoweredByBlurb
            style={{ position: 'absolute', top: -50 }}
            productId={state.currentPolicy.productId}
          />
        </VerticalCenter>
      )}

      {state.policyCount > 1 && (
        <ProgressBar
          percent={Math.floor((100 * (state.tab + 1)) / state.policyCount)}
          containerStyle={{
            width: '100%',
          }}
        />
      )}

      <SuggestedCoverageContext.Provider value={{ state, setState }}>
        <TabNavigator policy={currentPolicy} />
        <CoveragePopup
          popup={state.confirmPopup}
          setPopup={() => setState(confirmPopup(false))}
          onContinue={onContinue}
          title={popupTitle}
          verbiage={popupVerbiage}
          buttonText={popupButtonText}
        />

        <View
          style={{
            position: 'relative',
            width: '100%',
          }}>
          {currentError && <ErrorText>{currentError}</ErrorText>}
          <PriceFooter
            updating={state.updating}
            policyCount={policyCount}
            policy={currentPolicy}
          />
        </View>
      </SuggestedCoverageContext.Provider>
    </ContentUnified>
  );
};

export default SuggestedCoverageScreen;
