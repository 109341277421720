import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';
import { LoginScreenName, RootStackParamList } from '../../../../screen-config';
import { Spacer } from '../../../Components/SimpleComponents';
import { WaffleButtonAsync } from '../../../Components/WaffleButtonAsync';
import { WaffleTextFamily } from '../../../Components/WaffleTextSatoshi';
import { LightText } from './Shared';

export type AlreadyHaveAccountProps = {
  displayTextStyle: StyleProp<TextStyle>;
  signInBtnStyle: ViewStyle;
  signInTxtStyle: TextStyle;
  navigation: StackNavigationProp<RootStackParamList>;
};

const AlreadyHaveAccount = (
  props: AlreadyHaveAccountProps
): React.ReactElement => {
  const { displayTextStyle, signInBtnStyle, signInTxtStyle, navigation } =
    props;
  return (
    <>
      <LightText style={displayTextStyle}>
        {'Already have an account?'}
      </LightText>
      <Spacer y={1} />
      <WaffleButtonAsync
        style={{
          ...{
            backgroundColor: 'white',
            borderRadius: 15,
            border: '0.5px solid #F98D4B',
            boxShadow: '0px 0px 7px 1px rgba(249, 141, 75, 0.07)',
          },
          ...signInBtnStyle,
        }}
        textStyle={{
          ...{
            fontFamily: WaffleTextFamily.Medium,
            color: '#F98D4B',
            textTransform: 'none',
          },
          ...signInTxtStyle,
        }}
        name={'Sign in'}
        onPress={async () => navigation.navigate(LoginScreenName)}
      />
    </>
  );
};

export default AlreadyHaveAccount;
