import React, { ReactNode, useState } from 'react';
import {
  HorizontalSpread,
  VerticalCenter,
} from '../../Components/SimpleLayouts';
import { WaffleDarkGrey, WaffleLightGrey } from '../../Constants/Style';
import { Platform, TouchableWithoutFeedback } from 'react-native';
import WaffleText from '../../Components/WaffleText';
import { Spacer } from '../../Components/SimpleComponents';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Collapsible from 'react-native-collapsible';

export const CollapsibleBox = ({
  title,
  width,
  children,
}: {
  title: string;
  width: number;
  children: ReactNode;
}) => {
  const [isClosed, setIsClosed] = useState(true);

  return (
    <VerticalCenter
      style={{
        width,
        borderWidth: 1,
        borderColor: WaffleLightGrey,
        borderRadius: 10,
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 8,
        paddingRight: 8,
        shadowRadius: 10,
        ...Platform.select({
          ios: {
            borderColor: WaffleDarkGrey,
          },
          default: {
            borderColor: WaffleLightGrey,
            shadowOffset: { width: 0, height: 4 },
            shadowColor: 'rgba(108,62,12,0.5)',
            shadowOpacity: 1,
          },
        }),
        elevation: 5,
      }}>
      <TouchableWithoutFeedback onPress={() => setIsClosed((t) => !t)}>
        <HorizontalSpread>
          <WaffleText
            style={{
              fontSize: 16,
              fontWeight: '400',
              color: '#787878',
              textAlign: 'center',
            }}>
            {title}
          </WaffleText>

          <Spacer x={1} />

          <Icon
            testID="addtlOptionsCollapsible"
            name={isClosed ? 'chevron-down' : 'chevron-up'}
            size={18}
            onPress={() => setIsClosed((t) => !t)}
          />
        </HorizontalSpread>
      </TouchableWithoutFeedback>

      <Collapsible
        collapsed={isClosed}
        duration={Platform.OS === 'ios' ? 0 : 300}>
        <Spacer y={3} />
        {children}
      </Collapsible>
    </VerticalCenter>
  );
};
