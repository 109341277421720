import styled from 'styled-components/native';
import WaffleTextSatoshi from '../../../Components/WaffleTextSatoshi';
import WaffleTextVCS from '../../../Components/WaffleTextVCS';

// Satoshi
export const RegularText = styled(WaffleTextSatoshi.Regular)`
  color: #401f0a;
`;
export const LightText = styled(WaffleTextSatoshi.Light)`
  color: #401f0a;
`;

// Vice City Sans
export const VCSText = styled(WaffleTextVCS)`
  color: #401f0a;
`;

export const WaffleLogoImage = styled.Image`
  max-width: 213px;
  width: 100%;
  height: 91px;
`;
export const WaffleBackgroundImage = styled.Image`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const TheYouInsuranceImage = styled.Image`
  max-width: 517px;
  width: 100%;
  height: 46px;
`;
