import React, { useLayoutEffect } from 'react';
import { StepMessage } from '../Components/StepMessage';
import { StackScreenProps } from '@react-navigation/stack';
import { RootStackParamList } from '../../screen-config';

const StepMessageScreen = ({
  navigation,
  route,
}: StackScreenProps<RootStackParamList, 'Step Message'>) => {
  const nextScreen = route?.params.nextScreen ?? 'Home';
  const message = route?.params.message ?? 'NO-ID';
  const continueNavigation =
    route?.params.continueNavigation ??
    ((screen: keyof RootStackParamList) => navigation.navigate(screen));

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <></>,
      gestureEnabled: false,
    });
  }, [navigation]);

  return (
    <StepMessage
      text={message}
      onPress={async () => await continueNavigation(nextScreen)}
    />
  );
};

export default StepMessageScreen;
