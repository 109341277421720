import { useApolloClient } from '@apollo/client';
import React, { useLayoutEffect, useState } from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import { RootStackParamList } from '../../../screen-config';
import StartQuoteDesktopView from './StartQuoteDesktopView';
import { isFullWeb, isNativeOrMobileWeb } from '../../Helper/DeviceHelper';
import StartQuoteMobileView from './StartQuoteMobileView';

const StartQuoteScreen = ({
  navigation,
  route,
}: StackScreenProps<RootStackParamList, 'Start Quote'>) => {
  const client = useApolloClient();

  // token for getAuthContext.
  // if token (and maybe user) is set:
  //    if product ID is set, and customer doesn't have that product:
  //      create draft and continue.
  //    else
  //      go to product chooser (perhaps with message about already having product?)

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <></>,
    });
  }, [navigation]);

  if (isNativeOrMobileWeb()) {
    return (
      <StartQuoteMobileView
        client={client}
        navigation={navigation}
        productId={route?.params?.productId}
      />
    );
  }
  return (
    <StartQuoteDesktopView
      client={client}
      navigation={navigation}
      productId={route?.params?.productId}
    />
  );
};

export default StartQuoteScreen;
